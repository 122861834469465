import React from 'react'
import classes from './Post.module.scss'
import Img from 'gatsby-image'

import { updateImage } from '../../utils/updateImage'
import removeTags from '../../utils/remove-tags'

/**
 * Post component
 **/

const Post = ({
  title, slug, image, date, authorName, authorImage, articleDescription
}) => {
  const cls = [classes.PostText, 'general-link-style']
  return (
    <article className={classes.Post}>
      <a
        className={classes.PostLink}
        href={slug}
      >
        <div className={classes.PostImage}>
          <Img
            // removed largest image size from srcSet, if it exist
            fluid={{ ...updateImage(image),
              sizes: `(max-width: 440px) 280px, 394px`
            }}
            loading="eager"
            alt={removeTags(title)}
          />
        </div>
        <div className={classes.PostHeader}>
          {title}
        </div>
        <div className={classes.PostDescriptionWrapper}>
          <div className={classes.PostAuthorWrapper}>
            <div className={classes.AuthorImage}>
              <img
                src={authorImage}
                alt={authorName}
              />
            </div>
            <div className={classes.AuthorNameWrapper}>
              <div className={classes.AuthorName}>
                {authorName}
              </div>
              <div className={classes.PostDate}>
                {date}
              </div>
            </div>
          </div>
          <div
            className={cls.join(' ')}
            dangerouslySetInnerHTML={{ __html: articleDescription }}
          />
        </div>
      </a>
    </article>
  )
}

export default Post
