import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import SocialNetworkShare from '../components/UI/SocialNetworkShare/SocialNetworkShare'
import SEO from '../components/Seo'
import Post from '../components/Post/Post'
import { updateImage } from '../utils/updateImage'
import removeTags from '../utils/remove-tags'

const SingleArticle = ({ data }) => {
  const {
    article:
      {
        frontmatter: {
          title,
          date,
          author,
          authorImage,
          image,
          description,
          label,
          seo
        },
        fields: {
          slug
        },
        html
      }
  } = data

  const shareConfig = {
    articleUrl: `blog/${ slug }/`,
    title
  }

  return (
    <Fragment>
      <SEO data={ seo }
      />
      <div className="single-article-page">
        <article className="article">
          <h1
            className="article-header"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className="author-wrapper">
            <div className="author-image">
              <Img
                fixed={authorImage.childImageSharp.fixed}
                alt={removeTags(author)}
              />
            </div>
            <div className="authorname-date-block">
              <div className="authorname">
                {author}
              </div>
              <div className="article-date">
                {date}
              </div>
            </div>
          </div>
          <div className="article-image">
            <Img
              // removed largest image size from srcSet, if it exist
              fluid={{ ...updateImage(image.childImageSharp.fluid),
                sizes: `(max-width: 440px) 344px, (max-width: 992px) 736px, 834px`
              }}
              loading="eager"
              alt={title}
            />
            {description &&
              <div className="article-image-description">
                {description}
              </div>}
          </div>
          <div
            className="article-text list-style general-link-style"
            dangerouslySetInnerHTML={{
              __html: html
            }}
          />
          <div className="social-network">
            <ul className="social-network-list">
              <li>
                <SocialNetworkShare
                  socialNetworkName = "facebook"
                  shareConfig = {shareConfig}
                />
              </li>
              <li>
                <SocialNetworkShare
                  socialNetworkName = "twitter"
                  shareConfig = {shareConfig}
                />
              </li>
              <li>
                <SocialNetworkShare
                  socialNetworkName = "linkedIn"
                  shareConfig = {shareConfig}
                />
              </li>
            </ul>
          </div>
        </article>
        <section className="related-articles wrapper">
          <p className="related-section-header">{label}</p>
          <div className="related-articles-wrapper">
            <div className="blog-articles-wrapper">
              {data.relatedArticles.edges.map(({ node }) => {
                return (
                  <Post
                    key={node.id}
                    title={node.frontmatter.title}
                    slug={`/blog/${ node.fields.slug }/`}
                    image={node.frontmatter.image.childImageSharp.fluid}
                    date={node.frontmatter.date}
                    authorName={node.frontmatter.author}
                    authorImage = {node.frontmatter.authorImage.childImageSharp.fixed.base64}
                    articleDescription={node.excerpt}
                  />
                )
              })}
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  )
}

export default SingleArticle

export const postQuery = graphql`
  query ($slug: String!, $interestingForYouArticles: [String]!) {
    article: markdownRemark(
      fields: { slug: { eq: $slug }}
      fileAbsolutePath: { regex: "/content/individual-pages/articles/" }  
    ) {
      id
      html
      excerpt(pruneLength: 250)
      frontmatter {
        title
        date(formatString: "DD MMM YYYY")
        author
        authorImage {
          childImageSharp {
            fixed(
              width: 46, 
              height: 46, 
              quality: 80,
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        image {
          childImageSharp {
            fluid(
              maxWidth: 834, 
              maxHeight: 500, 
              quality: 100,
              cropFocus: CENTER,
              srcSetBreakpoints: [344, 736, 834]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        description
        label
        seo {
          canonical
          title
          description
          keywords
          robots
          image {
            childImageSharp {
              original {
                src
              }
            }
          }
          imageAlt
          twitterCard
        }
      }
      fields {
        slug
      }
    }
    relatedArticles: allMarkdownRemark (
      limit: 3
      sort: {
        fields: [frontmatter___date]
        order: DESC
      }
      filter: {
        frontmatter: { permalink: { in: $interestingForYouArticles }}
        fields: { slug: { ne: $slug }
      }, 
        fileAbsolutePath: { regex: "/content/individual-pages/articles/" }}
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            permalink
            title
            date(formatString: "DD MMM YYYY")
            author
            authorImage {
              childImageSharp {
                fixed (base64Width: 100){
                  base64
                }
              }
            }
            image {
              childImageSharp {
                fluid(
                  maxWidth: 394,
                  maxHeight: 237
                  quality: 85, 
                  cropFocus: CENTER,
                  srcSetBreakpoints: [224, 291, 344, 394]
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
